// src/App.tsx
import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './app/store/store';
import Login from './pages/Login/Login';
import Registration from './pages/Registration/Registration';
import Layouts from './pages/Layouts';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import CampaignDashboard from './pages/Dashboard/CampaignDashboard';
import AddClientsEmployees from './pages/AddClientsEmployees/AddClientsEmployees';
import Home from './pages/Home/Home';
import EditClientEmployee from './pages/AddClientsEmployees/EditClientEmployee';
import CreateCampaign from './pages/Campaign/CreateCampaign';
import Profile from './pages/Profile';
import ReferralForm from './utils/referralForm/ReferralForm';
import RewardDashboard from './pages/Reward/RewardDashboard';
import Cookies from 'js-cookie';
import TokenMonitor from './utils/TokenMonitor';
import SubscriptionPopup from './pages/Subscription/SubscriptionPopup';
import { handleUserLogin } from './utils/functions/handleUserLogin';
import { LoginData } from './pages/Login/LoginType';
import SubscriptionRedirectLoader from './pages/RedirectLoader/SubscriptionRedirectLoader';

import LandingPage from './pages/LandingPage/LandingPage';
import PublicLayout from './pages/LandingPage/PublicLayout';
import Pricing from './pages/Pricing/Pricing';
import FAQ from './pages/FAQs/FAQ';

import Support from './pages/Support/Support';
import TermsConditions from './pages/Policies/TermsConditions';
import PrivacyPolicy from './pages/Policies/PrivacyPolicy';
import AddFundsTabs from './pages/AddFunds';


function App() {
  const isAuthenticated = Cookies.get('userin') !== undefined;
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const {pathname} = useLocation();

  const [showPopup, setShowPopup] = useState(false);
  const email = Cookies.get('user_email') !== undefined ? Cookies.get('user_email') : '';
  const stripeRedirecting = Cookies.get('subscription_redirecting') !== undefined ? Cookies.get('subscription_redirecting') : '';
 
  const userData: LoginData = {
    email: email || "",
    password: "dummy value",
    refreshFlag: true,
  };

  useEffect(() => {
    if (isAuthenticated && !user) {
      handleUserLogin(dispatch, userData).then((loggedInUser) => {
      }).catch(error => {
        console.error('Error logging in user on page refresh:', error);
      });
    }

  // eslint-disable-next-line
  }, [isAuthenticated, user,dispatch]);

  useEffect(() => {
      if (isAuthenticated && user?.subscription_status === 'inactive' && !stripeRedirecting) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
    

  }, [isAuthenticated, user, stripeRedirecting,pathname]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if(!!isAuthenticated){
    return (<>
     <TokenMonitor />
     <SubscriptionPopup show={showPopup} handleClose={handleClosePopup} />
      <Routes>
        <Route path="/" element={<Layouts/>}>
          <Route index element={<Home/>}/>
          <Route path='*' element={<Home/>} />
          {
            (user?.subscription_status === 'trialing' || user?.subscription_status === 'active')?
            (stripeRedirecting)?<Navigate to="/checkout-redirect" /> :
            <>   
            <Route path='profile' element={ <Profile /> } />
            <Route path='dashboard' element={ <CampaignDashboard />} />
            <Route path='addClientsEmployees' element={ <AddClientsEmployees /> } />
            <Route path='editClientEmployee' element={<EditClientEmployee />} />
            <Route path='createCampaign' element={ <CreateCampaign />} />
            <Route path='reward-dashboard' element={ <RewardDashboard />} />
            <Route path='add-fund' element={<AddFundsTabs />} />
            </>:<></>
          }     
        </Route>
        <Route path='/faq' element={<PublicLayout/>}>
          <Route index element={<FAQ/> }/>
        </Route>
        <Route path='/contact' element={<PublicLayout/>}>
          <Route index element={<Support/>  }/>
        </Route>
        <Route path='/terms-conditions' element={<PublicLayout/>} >
        <Route index element={<TermsConditions/> } />  
          </Route>    
         <Route path='/privacy-policy' element={<PublicLayout/> } >
         <Route index element={<PrivacyPolicy/> } />    
          </Route>  
        <Route path="/checkout-redirect" element={stripeRedirecting ? <SubscriptionRedirectLoader /> : <Navigate to="/" />} />
        <Route path="referral-form/:id" element={<ReferralForm />} />
      </Routes>
    </>)
  }else{
    return (<>
     <TokenMonitor />
     <SubscriptionPopup show={showPopup} handleClose={handleClosePopup} />
      <Routes>
        <Route path="/" element={<PublicLayout/>}>
          <Route index element={<LandingPage/>}/>
          <Route path='*' element={<LandingPage/>} />
          <Route path='pricing' element={<Pricing/> } />
          <Route path='faq' element={<FAQ/> } />
          <Route path='contact' element={<Support/> } />   
          <Route path='terms-conditions' element={<TermsConditions/> } />    
          <Route path='privacy-policy' element={<PrivacyPolicy/> } />         
        </Route>
        <Route path="user/login" element={ <Login />} />
        <Route path="user/registration" element={ <Registration />} />
        <Route path='user/forgot-password' element={ <ForgotPassword />} />
        <Route path='user/reset-password' element={ <ResetPassword />} />
        <Route path="referral-form/:id" element={<ReferralForm />} />
      </Routes>
    </>)
  }
}

export default App;