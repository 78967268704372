import React from 'react';
import { Button, Modal } from 'react-bootstrap'

interface TangoWithdrawModalProps {
  show: boolean;
  handleClose: () => void;
}

const TangoWithdrawModal: React.FC<TangoWithdrawModalProps> = ({ show, handleClose }) => {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Refund Your Account Balance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        To process a refund or manage your account balance, please visit your profile page Refund tab. From there, you can initiate the refund and review your account details.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TangoWithdrawModal;
