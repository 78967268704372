import React, { useEffect, useState, useCallback } from 'react';
import { fetchCampaignData, deleteReferral, sendReward, denyReward } from '../../app/services/Campaign/campaignService';
import { Container, Row, Col, Table, Badge, Spinner, Alert } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Modal, notification, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import DetailsModal from './DetailsModal';
import { formatDateDefault } from '../../utils/functions/timeConversion'; 
import '../../style/common.css';
import './CampaignDashboard.css';
import Cookies from 'js-cookie';
import OverlayLoader from '../../components/OverlayLoader';

interface CustomToggleProps {
  children: React.ReactNode;
  eventKey: string;
}

function CustomToggle({ children, eventKey }: CustomToggleProps) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});
  return (
    <button
      type="button"
      className='btn-primary'
      onClick={decoratedOnClick}
      style={{ border: '1px solid rgb(212, 196, 219)', borderRadius: '12px', padding: '6px' }}
    >
      {children}
    </button>
  );
}

const CampaignDashboard = () => {
  const userId = parseInt(Cookies.get('user_id') || '0', 10);

  const [campaignData, setCampaignData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<any>(null);
  const [display, setDisplay] = useState<'campaign' | 'referral'>('campaign');
  const [search, setSearch] = useState({
    campaignName: '',
    referredType: '',
    referredName: '',
    dateReferred: '',
    receivedBy: '',
    status: '',
    isRewarded: ''
  });
  const [accordionEventKey, setAccordionEventKey] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(true);
  const [rewardLoading, setRewardLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(() => {
    fetchCampaignData(userId, "")
      .then(data => {
        setCampaignData(data);
        setLoading(false);
      })
      .catch(error => {
        notification.error({
          message: 'Error',
          description: error,
        });
        setError(error);
        setLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setAccordionEventKey('1');
      } else {
        setAccordionEventKey('0');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleApprove = (campaign_id: number, referred_id: number, received_by: string, received_by_emailId: string, reward_amount: number) => {
    const userId = parseInt(Cookies.get('user_id') || '0', 10);
    setRewardLoading(true);
  
    sendReward(campaign_id, referred_id, userId, received_by, received_by_emailId, reward_amount)
      .then(res => {
        const status = res.referral_status;
        setCampaignData(prevData =>
          prevData.map(campaign =>
            campaign.referred_id === referred_id
              ? { ...campaign, status } // Update status from response
              : campaign
          )
        );
        
        if (status === 'Approved') {
          notification.success({
            message: 'Success',
            description: `Reward successfully sent to ${received_by}.`,
          });
        } else if (status === 'Failed') {
          notification.error({
            message: 'Failed',
            description: `Failed to send the reward to ${received_by}.`,
          });
        } else {
          notification.info({
            message: 'Info',
            description: `The status of the reward for ${received_by} is ${status}.`,
          });
        }
        
        setRewardLoading(false);
      })
      .catch(error => {
        console.error("Error sending reward:", error);
        notification.error({
          message: 'Error',
          description: `There was an error sending the reward: ${error.message || error}`,
        });
        setRewardLoading(false);
      });
  };
  

  const [denyModalVisible, setDenyModalVisible] = useState(false);
  const [denyReason, setDenyReason] = useState('');
  const [currentReferredId, setCurrentReferredId] = useState<number | null>(null);

  const handleDeny = (referred_id: number) => {
    setCurrentReferredId(referred_id);
    setDenyModalVisible(true);
  };

  const confirmDeny = () => {
    if (currentReferredId !== null) {
      setRewardLoading(true)
      denyReward(currentReferredId, denyReason)
        .then(res => {
          setRewardLoading(false)
          setCampaignData(prevData =>
            prevData.map(campaign =>
              campaign.referred_id === currentReferredId ? { ...campaign, status: 'Denied', denyReason } : campaign
            )
          );
          setDenyModalVisible(false);
          setDenyReason('');
          setCurrentReferredId(null);
          notification.success({
            message: 'Success',
            description: 'Referral successfully denied.',
          });
        })
        .catch(error => {
          setRewardLoading(false)
          console.error("Error denying reward:", error);
          notification.error({
            message: 'Error',
            description: `There was an error denying the referral: ${error.message || error}`,
          });
        });
    }
  };

  const handleDelete = (id: string) => {
    deleteReferral(id)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'Campaign deleted successfully',
        });
        fetchData();
      })
      .catch(error => {
        notification.error({
          message: 'Error',
          description: error,
        });
      });
  };

  const handleShowCampaignDetails = (campaign: any) => {
    setModalData(campaign);
    setDisplay('campaign');
    setShowModal(true);
  };

  const handleShowReferredDetails = (campaign: any) => {
    setModalData(campaign);
    setDisplay('referral');
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearch(prevSearch => ({ ...prevSearch, [name]: value }));
  };

  const filteredData = campaignData.filter(campaign => {
    return (
      (campaign.campaign_name || "").toLowerCase().includes(search.campaignName.toLowerCase()) &&
      (campaign.referred_type || "").toLowerCase().includes(search.referredType.toLowerCase()) &&
      (campaign.referred_name || "").toLowerCase().includes(search.referredName.toLowerCase()) &&
      (campaign.referred_date || "").toLowerCase().includes(search.dateReferred.toLowerCase()) &&
      (campaign.received_by || "").toLowerCase().includes(search.receivedBy.toLowerCase()) &&
      (campaign.status || "").toLowerCase().includes(search.status.toLowerCase())
    );
  });  

  if (loading) {
    return <div style={{height:"80vh"}} className="d-flex justify-content-center align-items-center">
    <Spinner style={{color:"#655baa"}} animation="border" role="status" ><span className="sr-only"></span></Spinner>
    </div>
  }

  if (error) {
    return <Alert variant="danger">Error loading data: {error.message}</Alert>;
  }


  return (
    <div>
      <h1 className="text-center my-4">Campaign Dashboard</h1>
      <Container className='p-4'>
        <Row className="justify-content-center">
          <Col lg={12}>
            <Accordion defaultActiveKey={accordionEventKey}>
              <div className='pb-4 display-search'>
                <CustomToggle eventKey={accordionEventKey}>Search data</CustomToggle>
              </div>
              <Accordion.Collapse eventKey={accordionEventKey}>
                <div className="search-container">
                  <input
                    type="text"
                    placeholder="Search campaign name"
                    name="campaignName"
                    value={search.campaignName}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                  <input
                    type="text"
                    placeholder="Search referred type"
                    name="referredType"
                    value={search.referredType}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                  <input
                    type="text"
                    placeholder="Search refrral name"
                    name="referredName"
                    value={search.referredName}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                  <input
                    type="text"
                    placeholder="Search date referred"
                    name="dateReferred"
                    value={search.dateReferred}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                  <input
                    type="text"
                    placeholder="Search referred by"
                    name="receivedBy"
                    value={search.receivedBy}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                  <input
                    type="text"
                    placeholder="Search status"
                    name="status"
                    value={search.status}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                </div>
              </Accordion.Collapse>
            </Accordion>

            <div className="table-wrapper mt-4">
              <Table bordered striped className="">
                <thead className="thead-dark">
                  <tr>
                    <th>#</th>
                    <th>Campaign name</th>
                    <th>Campagin end date</th>
                    <th>Referred type</th>
                    <th>Referral name</th>
                    <th>Date referred</th>
                    <th>Referred by</th>
                    <th>Status</th>
                    <th>Approve/Deny reward</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((campaign: any, index: number) => (
                    <tr key={campaign.referred_id}>
                      <td>{index + 1}</td>
                      <td>
                        <button 
                          type="button"
                          onClick={() => handleShowCampaignDetails(campaign)}
                          className="link-button"
                        >
                          {campaign.campaign_name}
                        </button><br/>
                        Campaign ID: {campaign.campaign_id}
                      </td> 
                      <td>
                      {formatDateDefault(campaign.campaign_end_date)}
                      </td>
                      <td>{campaign.referred_type}</td>
                      <td>
                        <button 
                          type="button"
                          onClick={() => handleShowReferredDetails(campaign)}
                          className="link-button"
                        >
                          {campaign.referred_name}
                        </button>
                      </td>
                      <td>{formatDateDefault(campaign.referred_date)}</td>
                      <td>{campaign.received_by}</td>
                      <td>
                        <Badge bg={
                          campaign.status === 'Approved' ? 'success' :
                          campaign.status === 'Denied' ? 'danger' :
                          'warning'
                        }>
                          {campaign.status}
                        </Badge>
                      </td>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td>Approve</td>
                              <td className='p-1'>
                                <Popconfirm
                                title={`You are sending a reward in the amount of ${campaign.reward_amount} to ${campaign.received_by}. Click 'Send Reward' to send.`}
                                onConfirm={() => handleApprove(campaign.campaign_id, campaign.referred_id, campaign.received_by, campaign.received_by_emailId, campaign.reward_amount)}
                                okText="Send reward" 
                                cancelText="Cancel"
                                okButtonProps={{
                                  style:{
                                    backgroundColor:"#655baa"
                                  }
                                }}
                                >
                                  <input
                                    style={{
                                      accentColor:"#655baa"
                                    }}
                                    type="checkbox"
                                    checked={campaign.status === 'Approved'}
                                    disabled={campaign.status === 'Approved'}
                                    onChange={() => {}}
                                  />  
                              </Popconfirm>
                              </td>
                            </tr>
                            <tr>
                              <td>Deny</td>
                              <td className='p-1'>
                              <button
                                  type="button"
                                  onClick={() => handleDeny(campaign.referred_id)}
                                  className="link-button"
                                >
                                  <input
                                    type="checkbox"
                                    checked={campaign.status === 'Denied'}
                                    disabled={campaign.status === 'Approved'}
                                    onChange={() => {}}
                                  />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <Popconfirm
                          title="Are you sure to delete this campaign?"
                          onConfirm={() => handleDelete(campaign.referred_id)}
                          okText="Yes"
                          cancelText="No"
                          className="popconfirm-width"
                        >
                          <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {rewardLoading && <OverlayLoader/>}
          </Col>
        </Row>
      </Container>

      <Modal
        title="Deny Reward"
        open={denyModalVisible}
        onOk={confirmDeny}
        onCancel={() => setDenyModalVisible(false)}
        okText="Submit"
        cancelText="Cancel"
        okButtonProps={{
          className:"btn btn-primary"
        }}
      >
        <p>Please offer a reason for the denial that will be viewed by the employee or client that referred the person:</p>
        <input
          type="text"
          value={denyReason}
          onChange={(e) => setDenyReason(e.target.value)}
          className="form-control"
        />
      </Modal>

      <DetailsModal
        show={showModal}
        handleClose={handleCloseModal}
        data={modalData}
        display={display}
      />
    </div>
  );
};

export default CampaignDashboard;
