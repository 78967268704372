import { Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';
import BalanceCard from './BalanceCard';
import AchTransfer from './AchTransfer';

type Props = {}

const ProfileTabs = (props: Props) => {
    const [value, setValue] = useState<string>('balanceCard');

    return (
        <>
            <Tabs
                activeKey={value}
                onSelect={(tab) => setValue(tab || 'balanceCard')}
                className="m-4"
                variant='underline'
            >
                <Tab
                    eventKey="balanceCard"
                    title="CREDIT CARD"
                    className='mr-5'
                    style={{ marginRight: '10px' }}
                >
                    <BalanceCard />
                </Tab>
                
                <Tab
                    eventKey="achOrWire"
                    title="ACH/WIRE"
                >
                    {
                       <AchTransfer />
                    }
                </Tab>
            </Tabs>
        </>
    )
}

export default ProfileTabs;
