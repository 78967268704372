import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '../../app/services/UserServices';
import { notification } from 'antd';
import "react-phone-input-2/lib/bootstrap.css";
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import logo from '../../images/yes-referral-logo.png';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneNumber from '../../components/PhoneNumber/PhoneNumber';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../app/features/auth/AuthSlice';
import Cookies from 'js-cookie';
import OverlayLoader from '../../components/OverlayLoader';

const Registration = () => {
  const logoInputRef = useRef<HTMLInputElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<File | null>(null);
  const [new_phone, setNewPhone] = useState<any>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allowedTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const register = {
      first_name: (e.target as HTMLFormElement).firstname.value,
      last_name: (e.target as HTMLFormElement).lastname.value,
      phone_number: new_phone,
      password: (e.target as HTMLFormElement).password.value,
    };

    const email = (e.target as HTMLFormElement).email.value;
    const confirmEmail = (e.target as HTMLFormElement).confirm_email.value;

    if (email !== confirmEmail) {
      setIsLoading(false);
      return showNotification('error', 'Email and Confirm Email do not match.');
    }

    const nameRegex = /^[a-zA-Z]+$/;
    if (!nameRegex.test(register.first_name) || !nameRegex.test(register.last_name)) {
      setIsLoading(false);
      return showNotification('error', 'First name and last name should contain only letters.');
    }

    if (!isValidPhoneNumber(register.phone_number)) {
      setIsLoading(false);
      return showNotification('error', 'Please Enter a valid phone number');
    }

    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(register.password)) {
      setIsLoading(false);
      return showNotification('error', 'Password should be at least 8 characters long and include at least one number, one special character, one uppercase letter, and one lowercase letter.');
    }

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', register.password);
    formData.append('first_name', register.first_name);
    formData.append('last_name', register.last_name);
    formData.append('phone_number', register.phone_number.toString());
    formData.append('business_type', (e.target as HTMLFormElement).business_type.value);
    formData.append('business_name', (e.target as HTMLFormElement).business_name.value);
    if (logoImage) {
      formData.append('logo_image', logoImage);
    }

    registerUser(formData)
      .then(async (response) => {
        if ('status' in response && response.status === 201) {
          if (e.currentTarget) {
            e.currentTarget.reset();
          }

          dispatch(loginSuccess(response.data))
          Cookies.set("subscription_redirecting", "true")
          showNotification('success', 'Your registration is successfully completed');
          navigate("/checkout-redirect");
        } else if ('error' in response) {
          setIsLoading(false);
          showNotification('error', response.error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showNotification('error', String(error));
      });
  };

  const showNotification = (type: 'success' | 'error', message: string) => {
    notification[type]({
      message: type === 'success' ? 'Success' : 'Error',
      description: message,
      placement: 'topRight',
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      return;
    }
    if (!allowedTypes.includes(file.type)) {
      showNotification('error', 'Please upload a valid image file (JPEG, PNG, HEIC, HEIF)');
      resetFileInput()
      return;
    }
    if (file.size >= 5 * 1000 * 1024) {
      showNotification('error', 'Logo with a maximum size of 5MB is not allowed');
      resetFileInput()
      return;
    }
    setLogoImage(file);
  };

  const resetFileInput = () => {
    if (logoInputRef.current) {
      logoInputRef.current.value = '';
    }
  };

  return (
    <div className="container d-flex justify-content-center py-5">
      <div style={{ width: '100%', maxWidth: '30rem', marginBottom: "10vmin" }}>
        <div className="mb-4">
          <Link to="/">
            <img className='pb-4' src={logo} alt="Logo" style={{ width: '234px', height: 'auto' }} />
          </Link>
          <h2 className="mb-4">Create your YesReferral account</h2>
        </div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="mb-3">
            <label htmlFor="firstname" className="form-label">First name</label>
            <input type="text" className="form-control" name='firstname' id='firstname' required maxLength={30} />
          </div>
          <div className="mb-3">
            <label htmlFor="lastname" className="form-label">Last name</label>
            <input type="text" className="form-control" name='lastname' id='lastname' required maxLength={30} />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">Phone Number</label>
            <PhoneNumber value={new_phone} setValue={setNewPhone} />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email Address</label>
            <input type="email" className="form-control" name="email" id="email" required />
          </div>
          <div className="mb-3">
            <label htmlFor="confirm_email" className="form-label">Confirm Email Address</label>
            <input type="email" className="form-control" name="confirm_email" id="confirm_email" required />
          </div>
          <div className="mb-3">
            <label htmlFor="business_type" className="form-label">Business type</label>
            <input type="text" className="form-control" name='business_type' id='business_type' placeholder='e.g. Home Care' required maxLength={100} />
          </div>
          <div className="mb-3">
            <label htmlFor="business_name" className="form-label">Business name</label>
            <input type="text" className="form-control" name='business_name' id='business_name' required maxLength={100} />
          </div>
          <div className="mb-3">
            <label htmlFor="logo" className="form-label">Upload Logo</label>
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.heic,.heif"
              className="form-control"
              id="logo"
              ref={logoInputRef}
              onChange={handleLogoChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Create password</label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                required
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={togglePasswordVisibility}
                style={{
                  backgroundColor: 'transparent',
                  border: '1px solid #655baa',
                  color: '#655baa',
                }}
              >
                {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </button>
            </div>
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">Register</button>
          </div>
          {
            isLoading?<OverlayLoader/>:<></>
          }
        </form>
        <p className="mt-3 text-center">Already have a YesReferral account? <Link to="/user/login" className="text-decoration-none link">Sign in</Link></p>
      </div>
    </div>
  );
};

export default Registration;
