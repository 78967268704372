import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert } from 'react-bootstrap';
import { getRewardHistory } from '../../app/services/Reward/rewardDashboardService';
import '../../style/common.css';
import './RewardDashboard.css';
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { formatDateDefault } from '../../utils/functions/timeConversion';

interface RewardHistoryItem {
  referenceOrderID: string;
  status: string;
  amount: number | null;
  currencyCode: string | null;
  recipientFirstName: string | null;
  createdAt: string | null;
}

const RewardDashboard = () => {
  const [rewardHistory, setRewardHistory] = useState<RewardHistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const user_id = parseInt(Cookies.get('user_id') || '0', 10);

  useEffect(() => {
    getRewardHistory(user_id)
      .then(response => {
        const data = response.data;
        if (data && data.orders) {
          setRewardHistory(data.orders);
        } else {
          notification.error({
            message: 'Error',
            description: 'Unexpected response format for reward history.',
          });
        }
        setLoading(false);
      })
      .catch((err: Error) => {
        notification.error({
          message: 'Error',
          description: err.message,
        });
        setError(err);
        setLoading(false);
      });
  }, [user_id]);

  if (loading) {
    return (
      <div style={{ height: "80vh" }} className="d-flex justify-content-center align-items-center">
        <Spinner style={{ color: "#655baa" }} animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">Error loading data: {error.message}</Alert>;
  }

  return (
    <div className="reward-dashboard">
      <h1 className="text-center my-4">Reward History</h1>
      <div className="table-wrapper">
        <Table striped bordered hover>
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>Order ID</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Recipient</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {rewardHistory.map((item, index) => (
              <tr key={item.referenceOrderID}>
                <td>{index + 1}</td>
                <td>{item.referenceOrderID}</td>
                <td>{item.status}</td>
                <td>
                  {item.amount ? `${item.amount} ${item.currencyCode}` : 'N/A'}
                </td>
                <td>
                  {item.recipientFirstName ? item.recipientFirstName : 'N/A'}
                </td>
                <td>{item.createdAt ? formatDateDefault(item.createdAt) : 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default RewardDashboard;
