import { useEffect, useState } from 'react';
import styles from './AchTransfer.module.scss';
import { getAccountBalanceDetails } from '../../app/services/AddFunds/CreditCardService';
import { notification } from 'antd';

const AchTransfer = () => {

  const [accNumber, setAccNumber] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAccountBalanceDetails();
        if (res.data.data.accountNumber) {
          setAccNumber(res.data.data.accountNumber);
        } 
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch credit cards. Please try again later.',
        });
      }
    };
  
    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>ACH/Wire Transfer Information</h2>

      <div className={styles.section}>
        <ul className={styles.list}>
          <li>
            <strong>Send an ACH or wire transfer to Tango Card's bank:</strong>
            <ul>
              <li><strong>Bank Name:</strong> Wells Fargo Bank</li>
              <li><strong>Account Number:</strong> 4945710978</li>
              <li><strong>Routing Number:</strong> 121000248</li>
              <li><strong>Account Holder:</strong> Tango Card, Inc.</li>
              <li><strong>SWIFT Code:</strong> WFBIUS6S</li>
              <li>Normal processing time for wire transfers is one business day, and for ACH it is two business days.</li>
              <li><strong>Important:</strong> Please include your alphanumeric Account Number in the memo or note field when remitting funds.</li>
            </ul>
          </li>
        </ul>

        <ul className={styles.list}>
          <li>
            <strong>Contact <a href="mailto:funding@tangocard.com" style={{'color':'#655baa'}}>funding@tangocard.com</a> after initiating the ACH/Wire transfer with the following information:</strong>
            <ul>
              <li><strong>Amount transferred</strong></li>
              <li><strong>Account Number:</strong> {accNumber}</li>
              <li><strong>ACH Number</strong></li>
            </ul>
          </li>
        </ul>
      </div>

      <div className={styles.contact}>
        <p>If you have any questions or concerns regarding ACH/Wire transfers, you can contact Tango’s Funding Team. They will respond within one business day.</p>
        <p><strong>Funding email:</strong> <a href="mailto:funding@tangocard.com">funding@tangocard.com</a></p>
      </div>
    </div>
  );
};

export default AchTransfer;
