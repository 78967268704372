import { Tab, Tabs } from 'react-bootstrap';
import { useState } from 'react';
import ProfileSettings from './ProfileSettings';
import SubscriptionSettings from './SubscriptionSettings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store/store';
import { User } from '../../../app/features/auth/AuthSlice';
import SubscriptionForm from '../../Subscription/SubscriptionForm';
import Refund from './Refund';


type Props = {}

const ProfileTabs = (props: Props) => {
    const { user } = useSelector((state: RootState) => state.auth);
    const userDetails = user as User;
    const [value, setValue] = useState<string>('profile');

    return (
        <>
            <Tabs
                activeKey={value}
                onSelect={(tab) => setValue(tab || 'profile')}
                className="mb-3"
                variant='underline'
            >
                <Tab
                    eventKey="profile"
                    title="PROFILE"
                    className='mr-5'
                    style={{ marginRight: '10px' }}
                >
                    <ProfileSettings />
                </Tab>
                <Tab
                    eventKey="billing"
                    title="SUBSCRIPTION BILLING"
                >
                    {
                        ['active', 'trialing'].includes(userDetails?.subscription_status) ?
                            <SubscriptionSettings /> : <SubscriptionForm />
                    }
                </Tab>
                <Tab
                    eventKey="refund"
                    title="REFUND"
                >
                    {
                       <Refund />
                    }
                </Tab>
            </Tabs>
        </>
    )
}

export default ProfileTabs;
