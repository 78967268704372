import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import styles from './AddFundsModal.module.scss';

interface CreditCard {
  token: string;
  lastFourDigits: string;
  expirationDate: string;
}

interface AddFundsModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: (token: string, amount: number) => void;
  creditCards: CreditCard[];
  loading: boolean;
}

const AddFundsModal: React.FC<AddFundsModalProps> = ({ show, onClose, onConfirm, creditCards, loading }) => {
  const [selectedCardToken, setSelectedCardToken] = useState<string | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleCardSelect = (token: string) => {
    setSelectedCardToken(token);
    setError(null);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > 2000) {
      setError('Amount cannot exceed $2000.');
    } else {
      setAmount(value);
      setError(null);
    }
  };

  const handleConfirm = () => {
    if (!selectedCardToken) {
      setError('Please select a credit card.');
      return;
    }
    if (amount === null || amount <= 0) {
      setError('Please enter a valid amount.');
      return;
    }
    onConfirm(selectedCardToken, amount);
  };

  return (
    <Modal show={show} onHide={onClose} centered className={styles.modal}>
      <Modal.Header closeButton>
        <Modal.Title>Select a Credit Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.cardContainer}>
          <Form.Label>Select funding method *</Form.Label>
          {creditCards.map((card) => (
            <div
              key={card.token}
              className={`${styles.card} ${selectedCardToken === card.token ? styles.selectedCard : ''}`}
              onClick={() => handleCardSelect(card.token)}
            >
              <Form.Check
                type="radio"
                id={`card-${card.token}`}
                name="creditCard"
                checked={selectedCardToken === card.token}
                onChange={() => handleCardSelect(card.token)}
                required
                inline
              />
              <div className={styles.cardDetails}>
                <div className={styles.cardNumber}>**** **** **** {card.lastFourDigits}</div>
              </div>
            </div>
          ))}
          {error && !selectedCardToken && <div className={styles.error}>{error}</div>}
        </div>
        <Form.Group className="mt-3">
          <Form.Label>Enter funding Amount *</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter amount"
            required
            value={amount ?? ''}
            onChange={handleAmountChange}
            min="0"
            max="2000"
          />
          {error && (amount === null || amount <= 0) && <div className={styles.error}>{error}</div>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>Cancel</Button>
        <Button
          variant="primary"
          onClick={handleConfirm}
          disabled={loading}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFundsModal;
