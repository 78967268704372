
import { checkoutSession } from '../../app/services/Subscription/checkoutSession';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { notification } from 'antd';
import Cookies from 'js-cookie';
import { Dispatch } from 'react';
const public_stripe_key = process.env.REACT_APP_STRIPE_PUBLISHABLE_TEST_KEY;
const stripePromise: Promise<Stripe | null> = loadStripe(public_stripe_key || "");

interface redirectToSubscriptionInterface{
    afterAuth?:boolean
    setIsLoading?:Dispatch<boolean>,
    setIsError?:Dispatch<boolean>
}

export const redirectToSubscription=async({afterAuth,setIsLoading,setIsError}:redirectToSubscriptionInterface)=>{
    try {
        const res = await checkoutSession();
        const { id: sessionId } = res.data;
        const stripe = await stripePromise;
        if (stripe) {
            Cookies.remove("subscription_redirecting");
            if(setIsLoading){
                setIsLoading(false)
            }
        await stripe.redirectToCheckout({ sessionId });
        }
      } catch (error) {
        console.log(error)
        if(setIsLoading){
            setIsLoading(false)
        }
        if(setIsError){
            setIsError(true)
        }else{
                return  notification.error({
            message: 'Error',
            description: "Unable to create Subscription sesstion",
          });    
        }

      }
}