import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './CreditCardForm.module.scss';
import { registerPaymentMethod } from '../../app/services/AddFunds/CreditCardService';
import { usePaymentInputs } from 'react-payment-inputs';
import { notification } from 'antd';

interface CreditCardFormProps {
  onClose: () => void;
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({ onClose }) => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [ipAddress, setIpAddress] = useState('');

  const { getCardNumberProps, getExpiryDateProps, getCVCProps, meta } = usePaymentInputs();

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };
    fetchIpAddress();
  }, []);

  const formatExpirationDate = (expiry: string) => {
    const [month, year] = expiry.split('/').map(part => part.trim());
    if (month && year && year.length === 2) {
      const fullYear = `20${year}`;
      return `${fullYear}-${month}`;
    }
    return '';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (meta.erroredInputs.cardNumber || meta.erroredInputs.expiryDate || meta.erroredInputs.cvc) {
      notification.error({
        message: 'Validation Error',
        description: 'Please correct the errors in the form.',
      });
      return;
    }

    const formattedExpirationDate = formatExpirationDate(expiryDate);

    const data = {
      creditCard: {
        number: cardNumber,
        expiration: formattedExpirationDate,
        verificationNumber: cvc,
      },
      billingAddress: {
        firstName,
        lastName,
        addressLine1,
        city,
        state,
        postalCode,
        country,
        emailAddress,
      },
      ipAddress,
    };

    try {
      const response = await registerPaymentMethod(data);
      if (response.data.success) {
        notification.success({
          message: 'Success',
          description: response.data.message || 'Payment method added successfully',
        });
        onClose();
        window.location.reload();
      } else {
        notification.error({
          message: 'Unsuccessful',
          description: response.data.message || 'Failed to add payment method',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'An error occurred while registering the payment method. Please try again later.',
      });
      console.error('Error registering payment method:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.creditCardForm}>
      <h2>Add Your Credit Card Info</h2>

      <div className={styles.row}>
        <div className={styles.formGroup}>
          <label htmlFor="cardNumber">Card Number</label>
          <input
            {...getCardNumberProps({ onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCardNumber(e.target.value) })}
            id="cardNumber"
            value={cardNumber}
            placeholder="1234 5678 9012 3456"
            required
          />
          {meta.erroredInputs.cardNumber && <span className={styles.error}>{meta.error}</span>}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.formGroup}>
          <label htmlFor="expiryDate">Expiry Date</label>
          <input
            {...getExpiryDateProps({ onChange: (e: React.ChangeEvent<HTMLInputElement>) => setExpiryDate(e.target.value) })}
            id="expiryDate"
            value={expiryDate}
            placeholder="MM/YY"
            required
          />
          {meta.erroredInputs.expiryDate && <span className={styles.error}>{meta.error}</span>}
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="cvc">CVC</label>
          <input
            {...getCVCProps({ onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCvc(e.target.value) })}
            id="cvc"
            value={cvc}
            placeholder="123"
            required
          />
          {meta.erroredInputs.cvc && <span className={styles.error}>{meta.error}</span>}
        </div>
      </div>

      <hr className={styles.divider} />

      <div className={styles.row}>
        <div className={styles.formGroup}>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            placeholder="First"
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
            placeholder="Last"
            required
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <label htmlFor="addressLine1">Address</label>
          <input
            type="text"
            id="addressLine1"
            value={addressLine1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddressLine1(e.target.value)}
            placeholder="1234 Main St"
            required
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            value={city}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
            placeholder="City"
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            value={state}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
            placeholder="State"
            required
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <label htmlFor="postalCode">Postal Code</label>
          <input
            type="text"
            id="postalCode"
            value={postalCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
            placeholder="12345"
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="country">Country</label>
          <input
            type="text"
            id="country"
            value={country}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCountry(e.target.value)}
            placeholder="Country"
            required
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <label htmlFor="emailAddress">Email Address</label>
          <input
            type="email"
            id="emailAddress"
            value={emailAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailAddress(e.target.value)}
            placeholder="email@example.com"
            required
          />
        </div>
      </div>
      <button type="submit" className={styles.submitBtn}>Add Credit Card</button>
    </form>
  );
};

export default CreditCardForm;
