import React, { useState, useEffect } from 'react';
import { uploadCSV, fetchDataByUserId, deleteEmployeeClient } from '../../app/services/EmployeesClients';
import { notification, Popconfirm } from 'antd';
import { UserSwitchOutlined, IdcardOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button,Modal, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Table, Form } from 'react-bootstrap';
import '../../style/common.css';
import Cookies from 'js-cookie';
import './AddClientsEmployees.css';
import {
  InfoCircleOutlined
} from '@ant-design/icons';
import PhoneNumber from '../../components/PhoneNumber/PhoneNumber';
import OverlayLoader from '../../components/OverlayLoader';

const AddClientsEmployees = () => {
  // const { user } = useSelector((state: RootState) => state.auth);
  const userId = parseInt(Cookies.get('user_id') || '0', 10);
  const [file, setFile] = useState<File | null>(null);
  const [userData, setUserData] = useState<any[]>([]);
  const [type, setType] = useState<'Client' | 'Employee' | null>(null);
  const [importAllowed, setImportAllowed] = useState<boolean>(false);
  const [firstNameFilter, setFirstNameFilter] = useState<string>('');
  const [lastNameFilter, setLastNameFilter] = useState<string>('');
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [isLoading,setIsLoading]=useState(false)
  const [getExampleModalVisible,setGetExampleModalVisible]=useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchDataByUserId(userId, 0, '');
        setUserData(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
        notification.error({
          message: 'Error',
          description: 'Failed to fetch user data.',
        });
      }
    };

    fetchData();
  }, [userId, file, type]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = e.target.value as 'Client' | 'Employee';
    setType(selectedType);
    setImportAllowed(!!selectedType);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!file) {
      notification.error({
        message: 'Error',
        description: 'Please upload a file before importing.',
      });
      return;
    }

    if (!importAllowed) {
      notification.error({
        message: 'Error',
        description: 'Please select a type before importing the file.',
      });
      return;
    }

    if (file) {
      try {
        setIsLoading(true);
        const successMessage=await uploadCSV(file, userId, type!);
        const updatedUserData = await fetchDataByUserId(userId, 0, '');
        setIsLoading(false);
        setUserData(updatedUserData);
        notification.success({
          message: 'Success',
          description: successMessage?.message || 'File uploaded successfully.',
        });
      } catch (error: any) {
        setIsLoading(false);
        notification.error({
          message: 'Error',
          description: error.message || 'Something went wrong!',
        });
      }
    }
    
  };

  const handleDelete = async (emp_cli_id: number) => {
    try {
      await deleteEmployeeClient(emp_cli_id);
      const updatedUserData = await fetchDataByUserId(userId, 0, '');
      setUserData(updatedUserData);
      notification.success({
        message: 'Success',
        description: 'Employee or client deleted successfully.',
      });
    } catch (error) {
      console.error("Error deleting employee or client:", error);
      notification.error({
        message: 'Error',
        description: 'Failed to delete employee or client.',
      });
    }
  };

  const handleEdit = async (emp_cli_id: number) => {
    try {
      const updatedUserData = await fetchDataByUserId(userId, emp_cli_id, '');
      navigate(`/editClientEmployee`, { state: { userData: updatedUserData } });
    } catch (error) {
      console.error("Error in employee or client:", error);
    }
  };

  const handleFirstNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstNameFilter(e.target.value);
  };

  const handleLastNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastNameFilter(e.target.value);
  };

  const handleTypeFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTypeFilter(e.target.value);
  };

  const filteredUserData = userData.filter(user =>
    user.first_name.toLowerCase().includes(firstNameFilter.toLowerCase()) &&
    user.last_name.toLowerCase().includes(lastNameFilter.toLowerCase()) &&
    (typeFilter === '' || user.type === typeFilter)
  );
  
  const downloadOnClick=()=>{
    const fileUrl = process.env.PUBLIC_URL + '/assets/Sample.csv';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'Sample.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Container className='p-4'>
      <h1 className="text-center my-4">Add clients/employees</h1>
      <Form onSubmit={handleOnSubmit} className='pb-3' style={{borderBottom: '1px solid rgb(212, 196, 219)'}}>
        <Row className='mb-3'>
        <Col xs={12} md={8} className="pb-xs-3">
            <Form.Group controlId="formFile">
              <Form.Control
                type="file"
                accept=".csv,.xlsx,.ods,.xls"
                onChange={handleOnChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group>
              <Form.Select value={type || ''} onChange={handleTypeChange}>
                <option value="">Select Type</option>
                <option value="Client">Client</option>
                <option value="Employee">Employee</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Button htmlType="submit" className="btn btn-primary">
          IMPORT FILE
        </Button>
        <Button
        onClick={()=>setGetExampleModalVisible(true)} 
        type="primary" 
        className='btn btn-primary ms-4' 
        icon={<InfoCircleOutlined />}>
            Example Format
          </Button>
      </Form>

      <div className="m-2 d-flex flex-column flex-md-row align-items-start pt-3">
        <Form.Control
          type="text"
          placeholder="Search First Name"
          value={firstNameFilter}
          onChange={handleFirstNameFilterChange}
          className="mb-2 me-md-2"
        />
        <Form.Control
          type="text"
          placeholder="Search Last Name"
          value={lastNameFilter}
          onChange={handleLastNameFilterChange}
          className="mb-2 me-md-2"
        />
        <Form.Select value={typeFilter} onChange={handleTypeFilterChange} className="mb-2">
          <option value="">Search Type</option>
          <option value="Client">Client</option>
          <option value="Employee">Employee</option>
        </Form.Select>
      </div>

      {filteredUserData.length > 0 ? (
      <div className="table-wrapper">
        <Table bordered striped>
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Type</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {filteredUserData.map((user, index) => (
              <tr key={index}>
                <td>
                  {user.type === 'Client' ? (
                    <UserSwitchOutlined />
                  ) : (
                    <IdcardOutlined />
                  )}
                </td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.email}</td>
                <td>


                <PhoneNumber value={user?.phone_number || ''} setValue={()=>{}} readOnly={true}/>

                </td>
                <td>{user.type}</td>
                <td>
                  <Button type="primary" className='btn btn-primary' onClick={() => handleEdit(user.id)}>Edit</Button>
                </td>
                <td>
                  <Popconfirm
                    title="Are you sure to delete this employee or client?"
                    onConfirm={() => handleDelete(user.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger className='red' icon={<DeleteOutlined />} />
                  </Popconfirm>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    ) : (
      <p className="mt-4">No clients or employees found.</p>
    )}
         {
        isLoading?<OverlayLoader/>:<></>
      }

      <Modal
        title="Example Format"
        open={getExampleModalVisible}
        onCancel={() => setGetExampleModalVisible(false)}
        okText="Download Sample"
        cancelText="Close"
        onOk={downloadOnClick}
        okButtonProps={{
          className:"btn btn-primary"
        }}
      >
        <p>The uploaded CSV or Excel sheet should contain the following columns, though the sequence can be altered.</p>
        <div className='mt-2 d-flex p-3 border bg-body rounded align-items-center justify-content-between'>
          <p className='m-0 fw-bold'>First Name</p>
          <Divider type="vertical"
          style={{borderInlineStart:"1px solid #dee2e6", height:"1.5rem"}} />
          <p className='m-0 fw-bold'>Last Name</p>
          <Divider type="vertical" style={{borderInlineStart:"1px solid #dee2e6", height:"1.5rem"}} />
          <p className='m-0 fw-bold'>Email</p>
          <Divider type="vertical" style={{borderInlineStart:"1px solid #dee2e6", height:"1.5rem"}} />
          <p className='m-0 fw-bold'>Phone Number</p>

        </div>
      </Modal>
    </Container>
  );
};

export default AddClientsEmployees;
