import { Card, Container } from 'react-bootstrap';

const Support = () => {
    return (
        <Container className="d-flex justify-content-center align-items-center">
            <Card className="text-center" style={{ maxWidth: '600px', width: '100%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', margin: '2rem'}}>
                <Card.Body style={{ margin: '30px' }}>
                    <h4>WE’RE HERE TO HELP</h4>
                    <hr />
                    <h5>TangoCard support</h5>
                    <p>
                        For help with funding support, contact: <a href="mailto:funding@tangocard.com" style={{ color: '#655baa' }}>funding@tangocard.com</a>
                    </p>
                    <p>
                        or call 1-877-558-2646
                    </p>
                    <hr />
                    <h5>YesReferral support</h5>
                    <p>
                        For any queries, contact: <a href="mailto:support@yesreferral.com" style={{ color: '#655baa' }}>support@yesreferral.com</a>
                    </p>
                    <hr />
                    <h5>Questions regarding reward redemption for recipients</h5>
                    <p>
                        Visit the <a href="https://help.tangocard.com/s/article/How-do-I-use-my-Reward-Link" target="_blank" rel="noopener noreferrer" style={{ color: '#655baa' }}>Tango Redemption Guide</a> for a detailed article.
                    </p>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Support;
