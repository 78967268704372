import React, { useState, useEffect } from 'react';
import { notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './BalanceCard.module.scss';
import CreditCardForm from './CreditCardForm';
import Modal from './Modal';
import { Card as BootstrapCard, Button, Spinner as BootstrapSpinner } from 'react-bootstrap';
import { addFunds, getAccountCreditCards, removePaymentMethod } from '../../app/services/AddFunds/CreditCardService';
import CardDetail from './CardDetail';
import AddFundsModal from './AddFundsModal';

interface CreditCard {
  label: string;
  lastFourDigits: string;
  expirationDate: string;
  status: string;
  createdDate: string;
  activationDate: string;
  contactInformation: {
    fullName: string;
    emailAddress: string;
  }[];
  accountNumber: string;
  balance: number;
  token: string;
}

const BalanceCard: React.FC = () => {
  const [creditCards, setCreditCards] = useState<CreditCard[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAddFundsModalOpen, setIsAddFundsModalOpen] = useState<boolean>(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState<boolean>(false);
  const [expandedCardIndex, setExpandedCardIndex] = useState<number | null>(null);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isAddingFunds, setIsAddingFunds] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await getAccountCreditCards();
        if (Array.isArray(res.data.data.creditCards.data)) {
          setCreditCards(res.data.data.creditCards.data)
        } else {
          setCreditCards([]);
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch credit cards. Please try again later.',
        });
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);

  const handleAddFundsClick = () => {
    if (creditCards.length > 0) {
      setIsAddFundsModalOpen(true);
    } else {
      setIsPromptModalOpen(true);
    }
  };

  const handleAddFunds = async (token: string, amount: number) => {
    setIsAddingFunds(true);
    try {
      const response = await addFunds(token, amount.toString());
  
      if (response.status === 200) {
        notification.success({
          message: 'Success',
          description: response.data.message || `Funds have been added successfully to the card ending with ${token.slice(-4)}.`,
        });
        window.location.reload();
      } else {
        notification.error({
          message: 'Error',
          description: response.data.message || 'Unexpected response from the server. Please try again later.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was an error adding funds. Please try again later.',
      });
    } finally {
      setIsAddingFunds(false);
      setIsAddFundsModalOpen(false);
    }
  };

  const handleAddCardClick = () => {
    if (creditCards.length < 5) {
      setIsModalOpen(true);
    } else {
      notification.warning({
        message: 'Limit Reached',
        description: 'You can only add up to 5 credit cards.',
      });
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleClosePromptModal = () => setIsPromptModalOpen(false);

  const handleAddPaymentMethodClick = () => {
    setIsPromptModalOpen(false);
    handleAddCardClick();
  };

  const handleDeleteCard = (index: number) => {
    setDeleteIndex(index);
  };

  const confirmDeleteCard = async () => {
    if (deleteIndex !== null) {
      setIsDeleting(true);
      const cardToDelete = creditCards[deleteIndex];
    
      try {
        const response = await removePaymentMethod(cardToDelete.token);
    
        if (response.status === 200) {
          notification.success({
            message: 'Success',
            description: 'Credit card removed successfully.',
          });
  
          setCreditCards(prevCards => prevCards.filter((_, i) => i !== deleteIndex));
        } else {
          notification.error({
            message: 'Error',
            description: response.data.message || 'Unexpected response from the server. Please try again later.',
          });
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'There was an error removing the card. Please try again later.',
        });
      } finally {
        setIsDeleting(false);
        setDeleteIndex(null);
      }
    }
  };

  const handleToggleCardDetails = (index: number) => {
    setExpandedCardIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <div className={styles.balanceCard}>
      {isLoading ? (
        <div style={{ height: '80vh' }} className="d-flex justify-content-center align-items-center">
          <BootstrapSpinner
            animation="border"
            style={{ color: '#655baa' }}
            role="status"
          >
          </BootstrapSpinner>
        </div>
      ) : (
        <>
          <div className={styles.lowerSection}>
            <div style={{'marginBottom' : '0.5rem'}}>
              <Button 
                variant="primary" 
                onClick={handleAddCardClick}
              >
                Add Payment Method
              </Button>
              <Button 
                variant="primary" 
                onClick={handleAddFundsClick} 
                style={{ marginLeft: '1%' }}
              >
                Add funds
              </Button>
            </div>
            <span 
              className={styles.cardLimitInfo}
            >
              (You can add up to 5 credit cards)
            </span>
            <div className={styles.creditCardList}>
              {creditCards.length > 0 ? (
                creditCards.map((card, index) => (
                  <BootstrapCard
                    key={card.token}
                    className={styles.creditCard}
                  >
                    <BootstrapCard.Header>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Added credit card</h5>
                        <Button
                          variant="link"
                          className={styles.deleteCardBtn}
                          onClick={() => handleDeleteCard(index)}
                          style={{ color: 'red' }}
                          disabled={isDeleting}
                        >
                          <DeleteOutlined />
                        </Button>
                      </div>
                    </BootstrapCard.Header>
                    <BootstrapCard.Body className={styles.cardInfo}>
                      <div>
                        <div>Card number: **** **** **** {card.lastFourDigits}</div>
                        <div>Expires: {card.expirationDate}</div>
                        {expandedCardIndex === index && (
                          <div className={styles.additionalInfo}>
                            <CardDetail card={card} />
                          </div>
                        )}
                      </div>
                    </BootstrapCard.Body>
                    <BootstrapCard.Footer>
                      <Button variant="link" onClick={() => handleToggleCardDetails(index)}>
                        {expandedCardIndex === index ? 'Hide' : 'Display card info'}
                      </Button>
                    </BootstrapCard.Footer>
                  </BootstrapCard>
                ))
              ) : (
                <p>No credit cards available.</p>
              )}
            </div>
          </div>
        </>
      )}

      {isModalOpen && (
        <Modal onClose={handleCloseModal}>
          <CreditCardForm onClose={handleCloseModal} />
        </Modal>
      )}

      <AddFundsModal
        show={isAddFundsModalOpen}
        onClose={() => setIsAddFundsModalOpen(false)}
        onConfirm={handleAddFunds}
        creditCards={creditCards}
        loading={isAddingFunds}
      />

      {isPromptModalOpen && (
        <Modal onClose={handleClosePromptModal}>
          <div className={styles.promptModalContent}>
            <h3 className={styles.promptTitle}>No Credit Cards Found</h3>
            <p className={styles.promptMessage}>You need to add at least one credit card before adding funds.</p>
            <div className={styles.promptButtons}>
              <Button variant="secondary" onClick={handleClosePromptModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleAddPaymentMethodClick}>
                Add Payment Method
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {deleteIndex !== null && (
        <Modal onClose={() => setDeleteIndex(null)}>
          <div className={styles.promptModalContent}>
            <h3 className={styles.promptTitle}>Confirm Deletion</h3>
            <p className={styles.promptMessage}>
              Are you sure you want to delete the card ending in {creditCards[deleteIndex].lastFourDigits}?
            </p>
            <div className={styles.promptButtons}>
              <Button variant="secondary" onClick={() => setDeleteIndex(null)}>
                Cancel
              </Button>
              <Button variant="danger" onClick={confirmDeleteCard}>
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BalanceCard;
