import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import './Campagin.css';

interface UserData {
  id: number;
  email: string;
  full_name: string;
  type: string;
}

interface Props {
  userData: UserData[];
  selectedUsers: number[];
  handleUserSelect: (userIds: number[]) => void;
  campaign_type: string;
}

const FetchedEmployeesClients: React.FC<Props> = ({ userData, selectedUsers, handleUserSelect, campaign_type }) => {
  const handleSelectAll = () => {
    const allUserIds = userData.map(user => user.id);
    handleUserSelect(allUserIds);
  };

  const handleSelectNone = () => {
    handleUserSelect([]);
  };

  const handleCheckboxChange = (userId: number) => {
    const updatedSelectedUsers = selectedUsers.includes(userId)
      ? selectedUsers.filter(id => id !== userId)
      : [...selectedUsers, userId];
    handleUserSelect(updatedSelectedUsers);
  };

  return (
    <div className="m-3 pb-3" style={{ borderBottom: '1px solid rgb(212, 196, 219)' }}>
      <h2>{campaign_type}</h2>
      <div className='table-wrapper'>
         <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <Button
                variant=""
                className={`border checkbox-button checkbox-button-primary ${selectedUsers.length === userData.length ? 'active' : ''}`}
                onClick={handleSelectAll}
              >
              </Button>
              <Button
                variant=""
                className="reset-selection-button"
                onClick={handleSelectNone}
              >
                Reset selection
              </Button>
            </th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {userData.map((user, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  id={`user-${index}`}
                  checked={selectedUsers.includes(user.id)}
                  onChange={() => handleCheckboxChange(user.id)}
                />
              </td>
              <td>{user.full_name}</td>
              <td>{user.email}</td>
              <td>{user.type}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
     
    </div>
  );
};

export default FetchedEmployeesClients;
