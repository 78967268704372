import React from 'react';
import styles from './CardDetail.module.scss';
import { formatDateDefault } from '../../utils/functions/timeConversion';

interface CardDetailProps {
  card: {
    // Added for the future use
    // label: string;
    // lastFourDigits: string;
    // expirationDate: string;
    status: string;
    createdDate: string;
    // activationDate: string;
    contactInformation: {
      fullName: string;
      emailAddress: string;
    }[];
    // accountNumber: string;
  };
}

const CardDetail: React.FC<CardDetailProps> = ({ card }) => {
  return (
    <div className={styles.cardDetail}>
      {/* Added for the future use */}
      {/* <div>Label: {card.label}</div> */}
      {/* <div>Last Four Digits: {card.lastFourDigits}</div> */}
      {/* <div>Expiration Date: {card.expirationDate}</div> */}
      <div>
          {card.contactInformation.map(info => (
            <div key={info.emailAddress}>
              {info.fullName} ({info.emailAddress})
            </div>
          ))}
      </div>
      <div>Status: {card.status}</div>
      <div>Created Date: {formatDateDefault(card.createdDate)}</div>
      {/* <div>Activation Date: {card.activationDate}</div> */}
      {/* <div>Account Number: {card.accountNumber}</div> */}
    </div>
  );
};

export default CardDetail;
