import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  business_type: string;
  business_name:string;
  logo_image: File;
  subscription_status: string;
  is_canceled: boolean;
}

export interface AuthState {
  isLoading: boolean;
  emailVerify: boolean;
  error: string;
  isAuthenticated: boolean;
  access: string;
  refresh: string;
  user: User | null;
  subscription_status: string;
  is_canceled: boolean
}

const initialState: AuthState = {
  isLoading: false,
  emailVerify: false,
  error: "",
  isAuthenticated: false,
  access: "",
  refresh: "",
  user: null,
  subscription_status: "inactive",
  is_canceled:false
}

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action: PayloadAction<AuthState>) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      localStorage.setItem("accessToken", action.payload.access);
      state.access = action.payload.access;
      localStorage.setItem("refreshToken", action.payload.refresh);
      state.refresh = action.payload.refresh;
      state.user = action.payload.user;
      state.error = '';

      if (action.payload.user) {
        Cookies.set("user_id", JSON.stringify(action.payload.user.id));
        Cookies.set("user_email", action.payload.user.email);
      }
      Cookies.set("userin", action.payload.access);
    },
    loginFail: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    emailVerifySuccess: (state) => {
      state.emailVerify = true;
    },
    emailVerifyFail: (state) => {
      state.emailVerify = false;
    },
    profileUpdate: (state, action: PayloadAction<AuthState>) => {
      state.user = action.payload.user;
    },
    initializeAuth: (state, action: PayloadAction<{ access: string, refresh: string, user: User | null }>) => {
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      state.user = action.payload.user;
      state.isAuthenticated = !!action.payload.access;
    },
    logout: (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.access = "";
      state.refresh = "";
      state.user = null;
      state.error = "";

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      Cookies.remove("user_id");
      Cookies.remove("user_email");
      Cookies.remove("userin");
    }
  }
})

export const { loginPending, loginSuccess, loginFail, profileUpdate, initializeAuth, logout } = AuthSlice.actions

export default AuthSlice.reducer;
