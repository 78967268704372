import React, { useEffect, useState } from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import {
  UserOutlined,
  DesktopOutlined,
  TeamOutlined,
  HomeOutlined,
  CloseOutlined,
  LogoutOutlined,
  FundProjectionScreenOutlined,
  DollarOutlined,
  HistoryOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../images/yes-referral-logo.png';
import '../style/common.css';
import { useMediaQuery } from 'react-responsive';
import Cookies from 'js-cookie';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const { Sider } = Layout;

interface MenuItem {
  label: string;
  key: string;
  icon: JSX.Element;
  path: string;
}

const Layouts = () => {
  const location = useLocation();
  const { pathname } = location;
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1280px)' });
  const [defaultSelectedKey, setDefaultSelectedKey] = useState('home');

  const items: MenuItem[] = [
    { label: 'Home', key: 'home', icon: <HomeOutlined />, path: '/' },
    { label: 'Profile/Account', key: 'profile', icon: <UserOutlined />, path: `/profile` },
    { label: 'Add clients/employees', key: 'addClientsEmployees', icon: <TeamOutlined />, path: `/addClientsEmployees` },
    { label: 'Create campaign', key: 'createCampaign', icon: <FundProjectionScreenOutlined />, path: `/createCampaign` },
    { label: 'Campaign dashboard', key: 'dashboard', icon: <DesktopOutlined />, path: `/dashboard` },
    { label: 'Reward history', key: 'reward-dashboard', icon: <HistoryOutlined />, path: '/reward-dashboard' },
    { label: 'Add funds', key: 'add-fund', icon: <DollarOutlined />, path: '/add-fund' }
  ];

  const onItemClick = (path: string) => {
    if (isTabletOrMobile) {
      setDrawerVisible(false);
    }
    navigate(path);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      Cookies.remove("user_id");
      Cookies.remove("user_email");
      Cookies.remove("userin");
      navigate("/user/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  useEffect(() => {
    if (pathname === "/") {
      setDefaultSelectedKey("home");
    } else {
      setDefaultSelectedKey(pathname.substring(1));
    }
  }, [pathname]);

  return (
    <>
      {isTabletOrMobile ? (
        <>
          <Drawer
            style={{ minHeight: '100vh', backgroundColor: '#655baa' }}
            title=""
            placement="left"
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
          >
              <div style={{
            minHeight:"600px",
            height:"100%",
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-between",

          }}>
            <div>
            <div className="drawer-logo-container">
              <img className='p-4' src={logo} alt="Logo" style={{ width: '180px', height: 'auto' }} />
            </div>
            <Menu theme="dark" selectedKeys={[defaultSelectedKey]} mode="inline">
              {items.map(item => (
                <Menu.Item key={item.key} icon={item.icon} onClick={() => onItemClick(item.path)}>
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
            </div>
   
            <div style={{display:'flex', flexDirection:'column'}}>
              <Button
                type="primary"
                icon={<QuestionCircleOutlined />}
                onClick={() => onItemClick('/contact')}
                className="btn btn-primary"
                style={{ bottom: 30, left: 20, width:'20px' }}
              >
                Support
              </Button>
              <Button
                type="primary"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
                className="btn btn-primary"
                style={{ bottom: 20, left: 20, width:'20px' }}
              >
                Logout
              </Button>
            </div>

          </div>
       
            
          </Drawer>
          <main className="w-100" style={{ minHeight: '100vh' }}>
            <Header toggleDrawer={toggleDrawer} />
            <div className='flex-1'>
              <Outlet />
            </div>
            <Footer />
          </main>
        </>
      ) : (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#FFFFFF',paddingLeft:"300px" }}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            style={{ 
              backgroundColor: '#655baa',
              position:"fixed",
              height:"100vh",
              top:"0",
              left:"0",
              overflow:"auto"}}
          > 
          <div style={{
            minHeight:"600px",
            height:"100%",
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-between",

          }}>
            <div>
            <img className='p-4' src={logo} alt="Logo" style={{ width: '180px', height: 'auto' }} />
            <Menu theme="dark" selectedKeys={[defaultSelectedKey]} mode="inline" style={{ backgroundColor: '#655baa' }}>
              {items.map(item => (
                <Menu.Item key={item.key} icon={item.icon} onClick={(e: any) => { setDefaultSelectedKey(e.key); onItemClick(item.path); }}>
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
            </div>
   
            <div style={{display:'flex', flexDirection:'column'}}>
              <Button
                type="primary"
                icon={<QuestionCircleOutlined />}
                onClick={() => onItemClick('/contact')}
                className="btn btn-primary"
                style={{ bottom: 30, left: 20, width:'20px' }}
              >
                Support
              </Button>
              <Button
                type="primary"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
                className="btn btn-primary"
                style={{ bottom: 20, left: 20, width:'20px' }}
              >
                Logout
              </Button>
            </div>
          </div>
        
          </Sider>
          <main className="w-100">
            <Header toggleDrawer={toggleDrawer} />
            <div style={{maxHeight:"100vh"}} className='flex-1'>
              <Outlet />
            </div>
            <Footer />
          </main>
        </Layout>
      )}
    </>
  );
};

export default Layouts;
