import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './Refund.module.scss';
import { getAccountBalanceDetails } from '../../../app/services/AddFunds/CreditCardService';
import { notification } from 'antd';

const Refund = () => {

  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [accNumber, setAccNumber] = useState<number>(0);
  const [accId, setAccId] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAccountBalanceDetails();
        if (res.data.data.accountNumber) {
          setTotalBalance(res.data.data.currentBalance);
          setAccNumber(res.data.data.accountNumber);
          setAccId(res.data.data.account_identifier);
        } else {
          setTotalBalance(0);
        }
      } catch (error) {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch credit cards. Please try again later.',
        });
      } finally {
      }
    };
  
    fetchData();
  }, []);

  return (
    <Container className={styles.refundContainer}>
      <Row>
        <Col>
          <div className={styles.content}>
            <h1>Reclaim Your Funds From Tango:</h1>
            <h3>Total balance: ${totalBalance.toFixed(2)}</h3>
            <p>
            If you wish to refund your remaining balance from your Tango account, please contact Tango directly by emailing <a href="mailto:funding@tangocard.com">funding@tangocard.com</a> or by calling <strong>1-877-558-2646</strong> from Monday to Friday, 7:30 am to 5:00 pm PST.
            </p>
            <p>Tango will need the following information:</p>
            <ul>
              <li><strong>YesReferral Tango account number: {accNumber}</strong></li>
              {/* <li><strong>YesReferral Tango account identifier: {accId}</strong></li> */}
              <li><strong>Group name: yesreferraltest</strong></li>
              <li><strong>Group ID: G45701092</strong></li>
              <li>Your Name, Email, and Phone Number</li>
              <li>Physical Business Address</li>
              <li>Preferred Refund Method (Wire Transfer, Check, or Credit Card based on the original funding method associated with your account.)</li>
            </ul>
            <p>There are no additional fees for returning funds, and refunds can take up to 2-3 weeks.</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Refund;
