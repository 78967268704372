import { useEffect, useState } from 'react';
import { notification } from 'antd';
import ProfileTabs from './AddFundsTabs';
import styles from './BalanceCard.module.scss';
import { getAccountBalanceDetails } from '../../app/services/AddFunds/CreditCardService';

type Props = {};

const AddFundsTabs = (props: Props) => {
    const [totalBalance, setTotalBalance] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getAccountBalanceDetails();
                
                if (res && res.data && typeof res.data.data.currentBalance === 'number') {
                    setTotalBalance(res.data.data.currentBalance);
                } else {
                    setTotalBalance(0);
                }
            } catch (error) {
                console.error('Failed to fetch balance details:', error);
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch balance details. Please try again later.',
                });
                setTotalBalance(0);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className={styles.balanceCard}>
                <div className={styles.upperSection}>
                    <div className={styles.balanceInfo}>
                        <div className={styles.balanceHeader}>
                            <span className={styles.balanceLabel}>Total Balance: </span>
                            <span className={styles.balanceAmount}>${totalBalance.toFixed(2)}</span>
                        </div>
                        <div className={styles.fundingInfo}>
                            <h5 className={styles.sectionTitle}>Funding</h5>
                            <ul>
                                <li>Adding funds via ACH/wire or check is free. Funding via credit card incurs a 3.5% processing fee.</li>
                                <li><strong>Note:</strong> transactions are limited to once a day and $2,000 per transaction.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.profileTabsContainer}>
                <ProfileTabs />
            </div>
        </>
    );
};

export default AddFundsTabs;
