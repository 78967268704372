import React, { useState } from 'react';
import { Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';
import './SubscriptionDetails.css';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import TangoWithdrawModal from '../../../../TangoRedirect/TangoWithdrawModal';
import { cancelSubscription } from '../../../../../app/services/Subscription/cancelSubscription';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store/store';
import { notification } from 'antd';
import { BillingHistoryData } from './BillingHistoryData';
import { formatDate } from '../../../../../utils/functions/timeConversion';
import Cookies from 'js-cookie';
import OverlayLoader from '../../../../../components/OverlayLoader';

const SubscriptionDetails: React.FC<BillingHistoryData> = ({ paymentData }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [isLoading,setIsLoading]=useState(false)
  const userId = parseInt(Cookies.get('user_id') || '0', 10);
  const [show, setShow] = useState<boolean>(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState<boolean>(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCancel = () => {
    setIsLoading(true)
    setShow(false);
    cancelSubscription(userId).then((res) => {
      setIsLoading(false)
     
      notification.success({
        message: 'Success',
        description: `Subscription expires on ${formatDate(res.data.subscription_end_date, 'payment_date')}`,
      });
      window.location.reload();
      setShowWithdrawModal(true);
    }).catch((error) => {
      setIsLoading(false)
      notification.error({
        message: 'Error',
        description: 'Error occurred while canceling subscription!',
      });
    });
  };

  const handleWithdrawModalClose = () => setShowWithdrawModal(false);
  return (
    <>
      <h5 style={{ fontWeight: 700 }}>Billing frequency</h5>
      {paymentData && paymentData.length > 0 && (
        <>
          <Container className="d-flex mt-4 subscription-container">
            <Row>
              <Col>
                <Card className="subscription-card-details" style={{ borderRadius: '22px', backgroundColor: '#655BAA' }}>
                  <Card.Body className="subscription-card-body">
                    <Row>
                      <Col xs="auto">
                        <Badge
                          bg="rgb(0,0,0)"
                          pill
                          style={{ fontSize: '1rem', padding: '13px 20px', color: '#655BAA', backgroundColor: 'white' }}
                        >
                          Monthly
                        </Badge>
                      </Col>
                    </Row>
                    <Row className="mt-4 d-flex justify-content-between align-items-center">
                      <Col xs="auto">
                        <Card.Text style={{ color: '#FFFFFF' }}>
                          <span style={{ fontSize: '1.3rem' }}>${paymentData?.slice(-1)[0].amount}</span>/ month
                        </Card.Text>
                      </Col>
                      <Col className="text-end">
                        <Card.Text style={{ color: '#FFFFFF', fontSize: '0.9rem' }}>
                          {user?.subscription_status === 'trialing' ? 'Free trial ends on' : 'Next payment'}: {formatDate(paymentData?.slice(-1)[0].next_payment_date, 'next_payment_date').replace(/\//g, '.')}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}

      {user?.is_canceled === false && (
        <Button className="cancel-subscribe-btn" onClick={handleShow}>
          Cancel subscription
        </Button>
      )}

      {isLoading?<OverlayLoader/>:<></>}
      <CancelSubscriptionModal show={show} handleClose={handleClose} handleCancel={handleCancel} />
      <TangoWithdrawModal show={showWithdrawModal} handleClose={handleWithdrawModalClose} />
    </>
  );
};

export default SubscriptionDetails;
