import { axiosInstance } from "../../../config/interceptor";
import { REWARD_API } from "../../../utils/constants/apis.urls";

export const registerPaymentMethod = (data: any) => {
  return axiosInstance.post(REWARD_API.ADD_PAYMENT_METHOD, data);
}

export const getAccountCreditCards = () => {
  return axiosInstance.get(REWARD_API.GET_ACCOUNT_CREDIT_CARDS);
}

export const getAccountBalanceDetails = () => {
  return axiosInstance.get(REWARD_API.GET_ACCOUNT_BALANCE_DETAILS);
}
export const addFunds = (token: string, amount: string) => {
  const fundData = {
    token : token,
    amount : amount
  };
  return axiosInstance.post(REWARD_API.ADD_FUNDS, fundData);
}

export const removePaymentMethod = (token: string) => {
  return axiosInstance.post(REWARD_API.REMOVE_PAYMENT_METHOD, token);
}